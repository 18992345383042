/*
Template Name: Admin Template
Author: Niravjoshi / Wrappixel
File: scss
*/

/**
 * Table Of Content
 *
 *  1. Color system
 *  2. Options
 *  3. Body
 *  4. Typography
 *  5. Breadcrumbs
 *  6. Cards
 *  7. Dropdowns
 *  8. Buttons
 *  9. Typography
 *  10. Progress bars
 *  11. Tables
 *  12. Forms
 *  14. Component
 */
@import "all/custom/variables.scss";

// Import Bootstrap source files (Required file)
 @import "all/bootstrap/bootstrap.scss";
 @import "all/custom/custom.scss";

//This is for the icons (Required file)
@import "all/icons/font-awesome/css/fontawesome-all.css";
@import "all/icons/simple-line-icons/css/simple-line-icons.css";
@import "all/icons/weather-icons/css/weather-icons.min.css";
@import "all/icons/themify-icons/themify-icons.css";
@import "all/icons/flag-icon-css/flag-icon.min.css";
@import "all/icons/material-design-iconic-font/css/materialdesignicons.min.css";
@import "all/icons/crypto-icons/cryptocoins.css";

html {
  scroll-behavior: smooth;
}

body {
  overflow: auto!important;
}

// SIGNITY - CUSTOM CSS //
.expendlogo {
  width: 169px;
  padding: 13px 40px 10px 0;
  position: relative;
  left: 0;
  top: -1px;
}
.col-form-label-sm {
  font-size: 0.76562rem;
}
.form-control-sm {
  font-size: 0.76562rem;
}
.form-control-lg {
  height: calc(2.64062rem + 2px);
}
.valid-tooltip {
  font-size: 0.76562rem;
}
.invalid-tooltip {
  font-size: 0.76562rem;
}
.btn-sm,
.btn-group-sm > .btn {
  font-size: 0.76562rem;
}
.dropdown-header {
  font-size: 0.76562rem;
}
.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  height: calc(2.64062rem + 2px);
}
.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  font-size: 0.76562rem;
}

.custom-select-lg {
  height: calc(2.64062rem + 2px);
}
.pagination-sm .page-link {
  font-size: 0.76562rem;
}
.tooltip {
  font-size: 0.76562rem;
}
.popover {
  font-size: 0.76562rem;
}
.logo-text {
  left: 0;
}
.ReactTable .rt-td {
  padding: 2px 15px 2px 15px !important;
  border-right: none !important;
  font-size: 13px;
}

.ReactTable .rt-th {
  padding: 0 15px 0 15px !important;
  border-right: none !important;
  font-size: 13px;
}

.farmselect {
  position: relative;
  z-index: 9;
}
.logo-place {
  background-image: url("../images/kraal-logo.png");
  background-repeat: no-repeat;
  width: 175px;
  height: 96px;
  left: 0;
  background-color: #fff;
  background-position: center;
}

.rt-thead.-filters > .rt-tr .rt-th.wordwrap {
  padding-bottom: 5px !important;
  padding-top: 5px !important;
  background: #fff;
}

.pad-10 {
  padding: 10px;
}

.listing-heading ol.breadcrumb {
  margin-bottom: 0;
  padding-bottom: 0;
  background: none;
}

span.admin-class i.fa {
  padding-left: 5px;
}
span.admin-class {
  color: #353333;
  padding-left: 10px;
}
.notify-div {
  margin-right: 0px !important;
}
aside#track-filters {
  box-shadow: 22px 32px 52px #000 !important;
}
#track-filters .filters-footer {
  background: #100f0f;
}
.filters-footer button.outline.float-right.btn.btn-secondary {
  margin-right: 0;
}
#track-filters .filters-header {
  padding: 15px;
  background: #4fa931;
}
#track-filters .filters-header h5 {
  color: #fff;
  margin-bottom: 0 !important;
}
#track-filters .filters-header .close {
  opacity: 1;
  color: #fff;
}
.m-l-10 {
  margin-left: 10px;
}

//.leaflet-draw-section:last-child .leaflet-draw-actions li:first-child {
//  display: none;
//}

.animal-card-table .css-12jo7m5 {
  white-space: pre-wrap;
}
.animal-card-table.less-pad td {
  padding: 1px !important;
}
.rule_not-action {
  font-size: 18px;
}
.rule_not-action.snoozed {
  color: #e73a1e !important;
}
.rule_not-action.snoozable {
  color: #51ae32;
}

// **********************************
// dashboard page css
// **********************************

input,
select,
button {
  outline: none !important;
}
.page-wrapper > .page-content {
  padding-top: 15px;
}
.dashboard-title-row {
  align-items: center;
  margin-bottom: 15px;
}

.select-refresh-col {
  display: flex;
  justify-content: flex-end;
}
.select-refresh-col .dtr-select {
  width: 100%;
  max-width: 300px;
}
.select-refresh-col .fa-refresh:before {
  content: "\f021";
}
.select-refresh-col span.css-bgvzuu-indicatorSeparator {
  display: none;
}
.select-refresh-col .css-1wy0on6 {
  background: #21211f;
  color: #fff !important;
}
.refresh-btn button {
  background: white;
  border: 1px solid #dcd7d7;
  padding: 7px 10px;
  border-radius: 5px;
  margin-left: 10px;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  height: 37px;
  line-height: 17px;
}
.refresh-btn button i.fa {
  margin-left: 10px;
  font-size: 11px;
}
.refresh-btn button:focus i.fa.fa-refresh {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
}

.refresh-btn button:hover,
.refresh-btn button:focus {
  background: #4dab26;
  color: #fff;
}
.highlated-cols.row .hc-col i.fa.fa-bell {
  font-size: 40px;
  border-radius: 100px;
  border: 1px solid #fff;
  height: 80px;
  width: 80px;
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: center;
}
.highlated-cols.row .hc-col .card-body {
  width: auto;
  display: flex;
  flex-wrap: wrap;
  flex: inherit;
  flex-direction: column;
  color: #fff;
  padding-right: 0px;
}
.highlated-cols.row .card {
  flex-direction: row;
  align-items: center;
  min-height: 120px;
  padding: 0 20px;
}

.highlated-cols.row .card h5 {
  margin-bottom: 0px;
}
.highlated-cols.row .card h4 {
  font-size: 32px;
  margin-top: 5px;
}
.highlated-cols.row .card .card-title {
  font-size: 22px;
}
.highlated-cols .hc-1 .card {
  background: #4dab26;
}
.highlated-cols .hc-2 .card {
  background: #64c4cd;
}
.highlated-cols .hc-3 .card {
  background: #fe2714;
}
.highlated-cols .hc-1 svg {
  width: 80px;
  height: auto;
  fill: #fff;
  border-radius: 100px;
  border: 1px solid #fff;
  padding: 18px 18px;
}
.highlated-cols .hc-3 .hc-icon {
  width: 80px;
  height: auto;
  fill: #fff;
  border-radius: 100px;
  border: 1px solid #fff;
  padding: 18px 18px;
}
.highlated-cols .hc-3 .hc-icon img {
  width: 42px;
}
.heading-with-icons {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  padding: 10px 10px;
  border-bottom: 1px solid #e6e3e3;
}
.heading-with-icons h4 {
  margin-bottom: 0px;
}
.heading-with-icons .close-full-btns i.fa.fa-window-maximize {
  margin-right: 10px;
}
.close-full-btns i.fa.fa-times {
  color: #e73a1e;
}
.close-full-btns i.fa.fa-window-maximize {
  color: #524f4f;
}
.img-responsive {
  max-width: 100%;
}
.img-responsive.img-col {
  width: 100%;
}

.comman-col-box .cols-wrap {
  background: #fff;
  -webkit-box-shadow: 2px 6px 22px #e2e2e2;
  box-shadow: 2px 6px 22px #e2e2e2;
  margin-bottom: 30px;
  padding-bottom: 10px;
  width: 100%;
}
.comman-col-box {
  display: flex;
}
.Weather-widget-col .rw-main.type-5days {
  border-radius: 0;
}

.comman-col-box .ReactTable {
  margin: 10px;
}
.comman-col-box.LivestockBreakdown-widget-col .css-1pcexqc-container {
  margin: 10px;
  width: 200px;
}
.comman-col-box.LivestockBreakdown-widget-col
  span.css-bgvzuu-indicatorSeparator {
  display: none;
}
.comman-col-box.LivestockBreakdown-widget-col .css-1wy0on6 {
  background: #21211f;
  color: #fff !important;
}

.comman-col-box.LivestockBreakdown-widget-col .card {
  margin-bottom: 10px;
}
.comman-col-box.Weather-widget-col .rw-box {
  padding: 10px;
}
footer.footer {
  background: #fff;
  border-top: 1px solid #e8dfdf;
}
.gender-graph {
  display: block;
  width: 100%;
}
.gender-graph .gender-label {
  width: 100%;
  display: block;
  margin-bottom: 10px;
  max-width: 100%;
}
.gender-graph .gender-label .fa {
  margin-right: 10px;
}
.gender-cols {
  display: flex;
  align-items: center;
  border-left: 1px solid #e0d9d9;
  padding: 0 30px;
  flex-wrap: wrap;
}

.LivestockBreakdown-widget-col .card-body {
  padding: 0;
}

.LivestockBreakdown-widget-col .ReactTable .rt-tbody {
  max-height: 125px;
}

.statusIndicator.disconnected {
  background-color: #dc1a1a;
}

.close-full-btns {
  display: none;
}

.Notifications-widget-col .ReactTable .rt-table {
  overflow: hidden;
}
.icon-logo {
  width: 40px;
}

.row.site-new-row {
  z-index: 9;
}

aside#sidebarbg {
  overflow: hidden;
}
// aside#sidebarbg:after {
//     background:url("../images/kraal_darklogo.png") no-repeat;
//     position: absolute;
//     content: "";
//     height: 90px;
//     width: 134px;
//     bottom: 20px;
//     left: 26px;
//     right: 0px;
//     background-size: 128px;
// }
#main-wrapper[data-layout="vertical"][data-sidebartype="mini-sidebar"]
  .left-sidebar:after {
  display: none;
}

aside#sidebarbg:hover:after {
  display: block !important;
}

ul#sidebarnav {
  padding-top: 5px;
}
.row.site-new-row {
  z-index: 9;
}
.listing-heading h4 {
  margin-bottom: 0;
}

.ReactTable .rt-tbody .rt-tr {
  align-items: center;
  min-height: 32px;
}

.LivestockCount-widget-col .ReactTable .rt-th:first-child,
.LivestockCount-widget-col .ReactTable .rt-td:first-child,
.Notifications-widget-col .ReactTable .rt-th:first-child,
.Notifications-widget-col .ReactTable .rt-td:first-child,
.TopRules-widget-col .ReactTable .rt-th:first-child,
.TopRules-widget-col .ReactTable .rt-td:first-child {
  padding-left: 10px !important;
}
.pad-10.row button.float-right.mr-2.btn.btn-primary {
  margin-right: 0 !important;
}
.inner-header-top.row {
  background: none !important;
  margin: 0;
  padding: 9px 0;
  border: none !important;
}
.inner-header-top.row h4 {
  margin-bottom: 0px;
}
.inner-header-top.row .col-6.col-md-6.col-lg-6:first-child,
.inner-header-top.row .rcol8 {
  padding-left: 10px;
}
.inner-header-top.row .col-6.col-md-6.col-lg-6:last-child,
.inner-header-top.row .rcol8 {
  padding-left: 10px;
}
.inner-header-top.row .rcol4 {
  padding-right: 10px;
}
.inner-header-top.row
  .float-right.btn-group
  button.dropdown-toggle.btn.btn-secondary {
  margin-right: 0px !important;
}
ol.breadcrumb {
  margin-bottom: 0;
  padding-bottom: 0;
  background: none;
}
.ReactTable .position-relative {
  display: flex;
  align-items: center;
}
.ReactTable .position-relative input.form-check-input {
  top: 0px;
}
form#loginform button.btn.btn-primary.btn-lg.btn-block {
  padding: 10px !important;
}

.ReactTable .rt-thead.-header {
  min-height: 36px;
}

.livestockselect {
  min-width: 175px;
}
.livestockselect span {
  display: none;
}
.left-sidebar {
  padding-bottom: 40px;
}
.left-sidebar#sidebarbg .scrollbar-container {
  height: 100%;
}

.scroll-sidebar {
  height: calc(100% - 72px);
}

.statusIndicator.gray {
  background-color: #9d9d9d;
}

td.v-align-middle {
  vertical-align: middle;
}
.remove-mr {
  margin-right: 0 !important;
}

// ****************************************
// media query css
// ****************************************

@media (max-width: 1440px) {
  .highlated-cols.row .card .card-title {
    max-width: 210px;
  }
}

@media (max-width: 1400px) {
  .highlated-cols.row .card .card-title {
    font-size: 18px;
  }
  .sidebar-nav ul .sidebar-item .sidebar-link {
    color: #fff;
    padding: 7px 15px;
  }
  //  aside#sidebarbg:after {
  //     height: 93px;
  //     background-size: 100%;
  //     width: 130px;
  // }
}
@media (max-width: 1240px) {
  .highlated-cols.row .card .card-title {
    font-size: 15px;
  }
  .highlated-cols .hc-1 svg {
    width: 70px;
  }
  .highlated-cols.row .hc-col i.fa.fa-bell {
    font-size: 30px;
    height: 70px;
    width: 70px;
  }
  .highlated-cols .hc-3 .hc-icon {
    width: 70px;
  }
  .highlated-cols .hc-3 .hc-icon img {
    width: 32px;
  }
  .highlated-cols.row .card {
    padding: 0 10px;
  }
}

@media (max-width: 1180px) {
  .highlated-cols.row .hc-col .card-body {
    padding: 0 0 0 15px;
  }
  .highlated-cols.row .card {
    padding: 0 15px;
  }
  .highlated-cols.row .card .card-title {
    max-width: 100%;
    font-size: 17px;
  }
  .highlated-cols.row .card h4 {
    margin-bottom: 0;
    line-height: 27px;
  }
  .comman-col-box .ReactTable .rt-th,
  .comman-col-box .ReactTable .rt-td {
    font-size: 11px;
  }
  .select-refresh-col .dtr-select {
    max-width: 240px;
  }
}

@media (max-width: 1100px) {
  .highlated-cols.row .card {
    flex-direction: column;
    padding: 15px 20px;
    justify-content: center;
  }
  .highlated-cols.row .card .card-title {
    font-size: 18px;
    text-align: center;
  }
  .highlated-cols.row .card h4 {
    text-align: center;
    font-size: 25px;
  }
  .highlated-cols.row .hc-col .card-body {
    padding-left: 0px;
    margin-top: 10px;
  }
  .highlated-cols.row {
    flex-wrap: nowrap !important;
  }
  .highlated-cols.row .hc-col {
  }
  .highlated-cols.row .card {
    padding: 15px 10px;
  }
}

@media (max-width: 767px) {
  .left-sidebar#sidebarbg .scrollbar-container {
    height: 100%;
  }
  .left-sidebar {
    left: -250px;
  }
  .scroll-sidebar {
    height: calc(100% - 10px);
  }
  .highlated-cols.row {
    flex-wrap: wrap !important;
  }
  .logo-text {
    left: 0;
    padding: 0;
    right: 0;
    width: 220px;
    margin: 0 auto;
    display: block;
    text-align: center;
  }
  .expendlogo {
    width: 120px;
    padding: 0px 10px;
  }
  .highlated-cols.row .card {
    width: 100%;
  }
  .expendlogo {
    width: 129px;
    padding: 20px 10px;
  }
}
@media (max-width: 600px) {
  .animal-map-popup {
    position: unset;
    width: 100%;
    height: auto;
    margin-top: 20px;
    box-shadow: 0px 0px 12px #cac4c4;
    margin-bottom: 20px;
  }
}

@media (max-width: 480px) {
  .select-refresh-col {
    margin-top: 10px;
    flex-direction: column;
  }
  .refresh-btn button {
    margin-left: 0px;
    margin-top: 10px;
  }
  .select-refresh-col .dtr-select {
    max-width: 100%;
  }
}

// **********************************
// dashboard page css end
// **********************************

.logo-place.login-form-logo {
  background: none;
  width: auto;
  height: auto;
  position: relative;
  bottom: inherit;
  margin-top: 130px;
}
.logo-place.login-form-logo img {
  width: 332px;
  max-width: 100%;
}
.login-logo-col .p-5 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.filter-radio label {
  padding-left: 10px;
}
.filter-radio input {
  position: relative;
  top: 2px;
}
.calendar-widget-col .fc-toolbar.fc-header-toolbar {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
.calendar-widget-col h2 {
  font-size: 18px;
  padding-top: 0px;
  position: relative;
  top: 7px;
}
.calendar-widget-col .fc-ltr .fc-basic-view .fc-day-top .fc-day-number {
  float: none;
  text-align: center;
  width: 100%;
  display: block;
}
.calendar-widget-col .fc-unthemed td.fc-today {
  background: #56ab26;
}
.calendar-widget-col td.fc-today.fc-state-highlight {
  background: #56ab26;
}

.highlated-cols.row .hc-1 svg,
.highlated-cols.row .hc-2 svg,
.highlated-cols.row .hc-3 svg,
.highlated-cols.row .hc-2 i.fa.fa-bell,
.highlated-cols.row .hc-3 .hc-icon img {
  display: none;
}
.highlated-cols.row .hc-1 .hc-icon,
.highlated-cols.row .hc-2 .hc-icon,
.highlated-cols.row .hc-3 .hc-icon {
  position: relative;
  height: 75px;
  width: 75px;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 100%;
}

.highlated-cols.row .hc-3 .hc-icon {
  padding-top: 7px;
}
.highlated-cols.row .hc-1 .hc-icon {
  padding-top: 4px;
}
.highlated-cols.row .hc-2 .hc-icon {
  padding-top: 2px;
}
.highlated-cols.row .hc-1 .hc-icon:before {
  content: "\E801";
  font-family: "fontello";
  font-size: 45px;
  color: #fff;
}

.highlated-cols.row .hc-2 .hc-icon:before {
  content: "\E809";
  font-family: "fontello";
  font-size: 45px;
  color: #fff;
}

.highlated-cols.row .hc-2 .hc-icon:before {
    // content: "\E810";
    font-family: "fontello";
    font-size: 45px;
    color: #fff;
  }

.highlated-cols.row .hc-3 .hc-icon:before {
  content: "\E807";
  font-family: "fontello";
  font-size: 40px;
  color: #fff;
}

// global table height td
.table td {
  padding: 4px;
}

.global-table .table td:first-child,
.kraal-tag-global.card .tag-table td:first-child {
  width: 240px;
  padding-left: 10px;
}
.global-table table.table {
  margin-bottom: 0;
}

.global-table table.table h4 {
  margin-top: 8px;
  padding-left: 0;
}
.animal-single-detail-page.card .animal-photo .card {
  margin-bottom: 10px;
}
.animal-single-detail-page.card table {
  margin-bottom: 0px;
}
.animal-single-detail-page .more-btn {
  margin-top: -10px;
}
.kraal-tag-global.card .tag-table {
  width: 100%;
  margin-left: 0;
  margin-bottom: 0px;
}
.kraal-tag-global.card .pad-30.row {
  padding: 12px 10px;
}
.kraal-tag-global.card .details-title {
  font-size: 18px;
  margin-bottom: 0px;
}

.kraal-tag-global.card .statusIndicator.green,
.kraal-tag-global.card .statusIndicator.connected {
  top: 5px;
}

.global-heading-tag {
  padding: 12px 10px;
}
.global-heading-tag .details-title,
.global-heading-tag h4 {
  margin-bottom: 0;
}
.coming-soon-img img {
  width: 100%;
}
.kraal-tag-global.card .statusIndicator.gray,
.kraal-tag-global.card .statusIndicator.disconnected {
  top: 5px;
}
.gateway-breadcrum.row h4 {
  margin-bottom: 0;
}
.gateway-breadcrum nav {
  margin-bottom: 5px;
}
.gateway-breadcrum {
  margin: 0px;
}
.global-heading-tag.kraal-listing p.text-muted {
  margin-bottom: 0;
}
// global table height td end
#sidebarnav li.sidebar-item li.sidebar-item span.hide-menu {
  position: relative;
  padding-left: 12px;
}

#sidebarnav li.sidebar-item li.sidebar-item span.hide-menu:before {
  background: url(../images/arrow-tab.png) no-repeat;
  content: "";
  height: 15px;
  width: 9px;
  position: absolute;
  left: 0;
  top: 6px;
}

#sidebarnav li.sidebar-item li.sidebar-item .sidebar-link {
  padding: 4px 14px;
}
#sidebarnav li.sidebar-item li.sidebar-item a.sidebar-link.active span {
  color: #52ae32;
}
#sidebarnav
  li.sidebar-item
  li.sidebar-item
  a.sidebar-link.active
  span.hide-menu:before {
  background: url(../images/arrow-tab-hover.png) no-repeat;
  content: "";
  height: 15px;
  width: 9px;
  position: absolute;
  left: 0;
  top: 6px;
}
.sidebar-nav ul .sidebar-item .first-level {
  padding-bottom: 0;
}
ul#sidebarnav .has-arrow::after {
  display: none !important;
}

.tracking-detail .card {
  width: 240px;
}

.r-slider-right,
.r-slider-left {
  display: flex;
}
.r-slider-right .col.text-left {
  min-width: 130px;
}
.r-slider-left .col.text-left {
  min-width: 120px;
  display: inline-block;
}

.fixed-height {
  height: 150px;
  overflow: auto;
}

@media (max-width: 992px) {
  .animal-single-detail-page.range-sliders.card {
    display: block !important;
  }
  .animal-single-detail-page.range-sliders.card .rc-slider-mark-text {
    font-size: 10px;
  }
}

@media (max-width: 767px) {
  .range-sliders .r-slider-right .col.text-left {
    padding: 0;
    left: -10px;
  }
  .range-sliders .r-slider-left .text-left.col {
    padding: 0;
    left: -10px;
  }
  .range-sliders .r-slider-left {
    min-height: 70px;
  }
  .range-sliders .r-slider-right {
    min-height: 55px;
  }
  .r-slider-right,
  .r-slider-left {
    display: block !important;
  }
}
.allow-overflow .ReactTable .rt-td {
  overflow: unset !important;
  white-space: unset !important;
}
.react-datepicker-wrapper {
  width: 100%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media (min-width: 768px) {
  .topbar {
    z-index: 10 !important;
  }
  .left-sidebar {
    padding-top: 0px !important;
  }
  .scroll-sidebar {
    margin-top: -6px;
    height: calc(100% - 120px);
  }
  #main-wrapper[data-layout="vertical"] .left-sidebar {
    .navbar-brand {
      padding: 0 20px;
    }
    .logo-icon {
      padding: 13px 40px 0px 0px;
      margin-left: -10px;
      display: block;
    }
    .logo-text {
      display: none;
    }
  }
  #main-wrapper[data-layout="vertical"][data-sidebartype="full"] .left-sidebar,
  #main-wrapper[data-layout="vertical"] .left-sidebar:hover {
    .logo-icon {
      display: none;
    }
    .logo-text {
      display: block;
    }
    .scroll-sidebar {
      margin-top: unset;
    }
  }
}

// loader css
#cover-spin {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  display: none;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#cover-spin::after {
  content: "";
  display: block;
  position: absolute;
  left: 48%;
  top: 40%;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-color: black;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin 0.8s linear infinite;
  animation: spin 0.8s linear infinite;
}

.rg-icon-position {
  margin-top: -30px !important;
  margin-left: -12px !important;
}
.rg-pointer {
  cursor: pointer;
}

// animal popup
@media (min-width: 576px) {
  .modal-dialog.modal-dialog-lg {
    max-width: 1200px;
    margin: 1.75rem auto;
  }
}

.custom-marker {
  transform: perspective(40px) rotateX(20deg) rotateZ(-45deg);
  transform-origin: 50% 50%;
  border-radius: 50% 50% 50% 0;
  padding: 0 3px 3px 0;
  width: 16px;
  height: 16px;
  background: #2196F3;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-box-shadow: -1px 1px 4px rgba(0, 0, 0, .5);
  -moz-box-shadow: -1px 1px 4px rgba(0, 0, 0, .5);
  box-shadow: -1px 1px 4px rgba(0, 0, 0, .5);
}

.custom-marker:after {
  content: '';
  width: 8px;
  height: 8px;
  margin: 4px 0 0 4px;
  background: #ffffff;
  position: absolute;
  border-radius: 50%;
}

.toast-notification--high {
  background: #ff0000!important;
}

.toast-notification--medium {
  background: #febf00 !important;
  color: #000;

  .Toastify__close-button {
    color: #000;
  }
}

.toast-notification--low {
  background: #cccccc !important;
  color: #000;

  .Toastify__close-button {
    color: #000;
  }
}

.select-form-control {
  &.form-control {
    padding: 0;
    border: none;

    &.is-invalid {
      .react-select__control {
        &:hover {
          border: 1px solid red;
          box-shadow: none;
        }
        border: 1px solid red;
        box-shadow: none;
      }

      .react-select__control--is--focused {
        border: 1px solid red;
        box-shadow: none;
      }

    }
    &.is-invalid ~ .invalid-feedback {
      margin-top: 0.5rem;
    }
  }
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 2em;
  height: 2em;
}
.loader {
  margin: -1.5px 10px 0 0;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.3em solid rgba(255, 255, 255, 0.2);
  border-right: 0.3em solid rgba(255, 255, 255, 0.2);
  border-bottom: 0.3em solid rgba(255, 255, 255, 0.2);
  border-left: 0.3em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.black-background {
  background-color: $gray-800;
  color: $white;
}
