.map {
  height: 400px;
  overflow: hidden;
  z-index: 1;
}

.map.farms {
  .leaflet-draw-edit-edit,
  .leaflet-draw-edit-remove,
  .leaflet-draw-toolbar {
    display: none;
  }
}

.map.farms.editing {
  .leaflet-draw-edit-edit,
  .leaflet-draw-edit-remove,
  .leaflet-draw-toolbar {
    display: block;
  }
}
